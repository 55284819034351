<template>
  <flag />
</template>

<script>
import flag from "@/components/flag.vue";
export default {
  components: {
    flag,
  },
};
</script>

<style></style>
